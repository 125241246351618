import {
    authPost,
    authGet,
    authPut,
    authPatch,
    publicGet,
    publicPost,
    authDelete,
    createCancelToken
} from "../helpers/httpHelpers";
import {SERVER_URL} from "./apiHelper";

let productListCancelSource = null;

const getProductDetails = (
    productSlugId = "",
    isSlug= false,
    ownorg = false
)=> authGet(`${SERVER_URL}/products/${productSlugId}?isSlug=${isSlug}&ownorg=${ownorg}`);

const getVersionSpecificProductDetails = (
    productId = "",
    versionNumber = ""
)=> authGet(`${SERVER_URL}/products/getVersionSpecificProductDetails?productId=${productId}&versionNumber=${versionNumber}`);

const updateProduct = (productId, payload) => authPut(`${SERVER_URL}/products/${productId}`, payload);

const updateListProduct = (productId, payload) => authPost(`${SERVER_URL}/products/${productId}/updateList`, payload);

// Draft
const addDraftProduct = payload => authPost(`${SERVER_URL}/products/addDraft`, payload, {cancelToken: createCancelToken().token});

const getDraftProductDetails = productId => authGet(`${SERVER_URL}/products/${productId}/draftDetail`);

const getDraftProductsList = (
    pagination = JSON.stringify({ page: 1, pageSize: 5 })
) => authGet(`${SERVER_URL}/products/drafts?pagination=${pagination}`);

const getDownloadsSummary = (
    pagination = JSON.stringify({ page: 1, pageSize: 5 }), groupBy="publisherOrgs", startDate, endDate
) => authGet(`${SERVER_URL}/products/downloads/summary?pagination=${pagination}&groupBy=${groupBy}&startDate=${startDate}&endDate=${endDate}`);

const getProductsDownloadDetail = (
    pagination = JSON.stringify({ page: 1, pageSize: 5 }), groupBy="publisherOrgs", orgid
) => authGet(`${SERVER_URL}/products/downloads?pagination=${pagination}&key=${groupBy}&value=${orgid}`);

const getSolutionLogos = (orgId, returnDefault) => authGet(`${SERVER_URL}/solution-logos/${orgId}?returnDefault=${returnDefault}`);

const deleteDraftProduct = productId => authPost(`${SERVER_URL}/products/${productId}/deleteDraft`);

const updateDeploymentImageUrl = (productId, payload) => authPatch(
    `${SERVER_URL}/products/${productId}/deployment-image`, payload);

const addNewProduct = payload => authPost(`${SERVER_URL}/products`, payload);

const addNewListProduct = payload => authPost(`${SERVER_URL}/products/addList`, payload);

const changeProductStatus = (productId, status) => authPost(`${SERVER_URL}/products/${productId}/status/${status}`);
const deleteProduct = (productId, productStatus) => authPost(`${SERVER_URL}/products/delete`, { productId, productStatus });

const getDashboardList = (
    pagination = JSON.stringify({ page: 1, pageSize: 4 }),
    filters = JSON.stringify({}),
    search = "",
    sortBy = JSON.stringify({}),
    filterType = ""
) => authGet(
    `${SERVER_URL}/products/dashboard?pagination=${pagination}&filters=${filters}&search=${search}&sortBy=${sortBy}&filterType=${filterType}`);

const getProductsList = (
    pagination = JSON.stringify({ page: 1, pageSize: 4 }),
    filters = JSON.stringify({}),
    search = "",
    sortBy = JSON.stringify({}),
    filterType = "",
    includeTestOrgSolutions=false,
    noCancel = false
) => {
    // cancel the prefivious request if token exists and create a new token for the new request.
    productListCancelSource && productListCancelSource.cancel("request cancelled");
    productListCancelSource = !noCancel ? createCancelToken() : null;
    return authGet(
        `${SERVER_URL}/products?managed=false&pagination=${pagination}&filters=${filters}&search=${search}&sortBy=${sortBy}&filterType=${filterType}&includeTestOrgSolutions=${includeTestOrgSolutions}`,
        {}, { cancelToken: productListCancelSource?.token });
};

const getManagedProductsList = (
    pagination = JSON.stringify({ page: 1, pageSize: 4 }),
    filters = JSON.stringify({}),
    search = "",
    sortBy = JSON.stringify({}),
    filterType = ""
) => authGet(
    `${SERVER_URL}/products?managed=true&pagination=${pagination}&filters=${filters}&search=${search}&sortBy=${sortBy}&filterType=${filterType}`);

const getDeployableProductList = (
    pagination = JSON.stringify({ page: 1, pageSize: 100 }),
    filters = JSON.stringify({}),
    sortBy = ""
) => authGet(
    `${SERVER_URL}/deployment/all?pagination=${pagination}&filters=${filters}&sortBy=${sortBy}`);

const getRatingsList = () => authGet(`${SERVER_URL}/review/products/ratings/all`);
const getProductReviews = id => authGet(`${SERVER_URL}/review/products/${id}`);
const postProductReview = (id, review) => authPost(`${SERVER_URL}/review/products/${id}`, review);

/**
 * param 'url' can has 'orders' value for get all orders of Consumer
 * and 'deployed' value for get all Deployed products of Publisher
 *
 * @param {string} url
 * @returns {*}
 */
const getBillingsOrDeployedsList = url => authGet(`${SERVER_URL}/billing/${url}`);
const getProductBillings = productId => authGet(`${SERVER_URL}/billing/products/${productId}`);
const getDeployedProductConsumers = productId => authGet(`${SERVER_URL}/billing/deployed/${productId}/consumers`);

const subscribeSaasProduct = (id, payload) => authPost(`${SERVER_URL}/deployment/products/saas/${id}/subscribe`,
    payload);

const getOperationSystems = () => authGet(`${SERVER_URL}/products/techspecs/os/list`);
const getContentTypes = () => authGet(`${SERVER_URL}/products/techspecs/contenttypes/list`);
const getSolutionAreas = () => authGet(`${SERVER_URL}/products/techspecs/solutionarea/list`);
const getCertificationTypeList = () => authGet(`${SERVER_URL}/products/certificationtypes`);
const getVmwareProducts = (
    pagination = JSON.stringify({ page: 1, pageSize: 5, enable: false })
) => authGet(`${SERVER_URL}/vmwareproducts/?pagination=${pagination}`);
const syncVmwareProducts = () => authPost(`${SERVER_URL}/syncvmwareproducts`);

const unsubscribeDeployment = payload => authPost(`${SERVER_URL}/deployment/unsubscribe/multiple`, payload);
const unsubscribeRegistrySolution = subscriptionId => authDelete(`${SERVER_URL}/subscriptions/${subscriptionId}`);

const archiveMetaFile = payload => authPost(`${SERVER_URL}/products/${payload.productId}/meta-files/${payload.metaFileId}/archive`, payload);

const archiveOwnProduct = productId => authPost(`${SERVER_URL}/products/${productId}/archive`);
const republishOwnProduct = productId => authPost(`${SERVER_URL}/products/${productId}/republish`);

const searchProducts = (text, isOwnApps = false) => authGet(`${SERVER_URL}/products/search?textsearch=${text}&ownorg=${isOwnApps}`);
const adminSearchProducts = (text, isOwnApps = false) => authGet(`${SERVER_URL}/products/search?textsearch=${text}&ownorg=${isOwnApps}&testOrgSolutions=true`);

const changeProductOvaStatus = payload => authPost(`${SERVER_URL}/products/version/archive`, payload);

const changeBlueprintFileStatus = (productId, payload) => authPost(`${SERVER_URL}/products/${productId}/blueprint/status`, payload);


const getCategories = () => authGet(`${SERVER_URL}/products/categories/list`);

const getPreSignedDownloadUrl = (productId, payload) => authPost(`${SERVER_URL}/products/${productId}/download`, payload);

const getPreSignedTempUrl = payload => authPost(`${SERVER_URL}/products/eulaurl`, payload);

//Solution Area Type Details API

const getSolutionAreaDetailsList = (level = 0) => publicGet(`${SERVER_URL}/products/solutionarea?level=${level}`);
const getDockerLogin = payload => authPost(`${SERVER_URL}/deployments/dockerlogin`, payload);
const getGarRoCredentials = () => authPost(`${SERVER_URL}/repositories/gar-ro-token`);


const createRequestInfo = payload => publicPost(`${SERVER_URL}/products/requestinfo`, payload);

const getLeadsList = () => authGet(`${SERVER_URL}/products/requestinfo`);

const deleteLeadInfo = payload => authDelete(`${SERVER_URL}/products/requestinfo`, payload);

const requestCaptcha = () => publicGet(`${SERVER_URL}/request-captcha`);

// Certification CRUD
const createUpdateCertification = payload => authPost(`${SERVER_URL}/products/certificationType`, payload);

const getCertification = (
    displayName="",
    isEnabled=false
) => authGet(`${SERVER_URL}/products/certificationType?displayName=${displayName}&isEnabled=${isEnabled}`);

const deleteCertification = payload => authDelete(`${SERVER_URL}/products/certificationType`, payload);

const updateAddonAssetUrl = (productId, payload) => authPatch(`${SERVER_URL}/products/${productId}/addon-asset`, payload);

const setFeaturedProductStatus = (productId, featureStatus) => authPost(`${SERVER_URL}/products/admin/updateFeatureStatus?productid=${productId}&featureStatus=${featureStatus}`);

const setPopularProductStatus = (productId, popularStatus) => authPost(`${SERVER_URL}/products/admin/updatePopularStatus?productid=${productId}&popularStatus=${popularStatus}`);

const getMPCategoryHeirarchy = () => authGet(`${SERVER_URL}/categories/dashboard`);

const getMPCategories = (level = 1) => authGet(`${SERVER_URL}/products/admin/categories?level=${level}`);

const deleteMpCategoriesAdmin = payload => authDelete(`${SERVER_URL}/products/admin/categories`, payload);

const addMpCategoriesAdmin = ({payload}) => authPost(`${SERVER_URL}/products/admin/categories`, payload);

const updateMpCategoriesAdmin = ({payload, uuid})=>  authPut(`${SERVER_URL}/products/admin/categories/${uuid}`, payload);

const changeProductVersionStatus = payload => authPost(`${SERVER_URL}/products/version/status`, payload);

const getversionHeaderDetails = payload => authPost(`${SERVER_URL}/products/getVersionSpecificProductDetails`, payload);

const getAllCompatiblePlatforms = () => authGet(`${SERVER_URL}/compatible-platforms`);

const createAlertForSolution = (productId, payload) => authPost(`${SERVER_URL}/products/${productId}/alerts`, payload);

const updateAlertForSolution = (productId, alertId, payload) => authPut(`${SERVER_URL}/products/${productId}/alerts/${alertId}`, payload);

const getAllAlertsData = productId => authPost(`${SERVER_URL}/products/${productId}/alerts`);

const getAlertSeverityList = () => authGet(`${SERVER_URL}/products/alerts/severitylist`);

//SKU
const getSKUList = (pagination = JSON.stringify({ page: 1, pageSize: 5, enable: false })) => authGet(`${SERVER_URL}/products/skus/?pagination=${pagination}`);
const updateSKU = (skuID, payload) => authPut(`${SERVER_URL}/products/skus/${skuID}`, payload);

// Alerts
const getAllAlerts = productId => authGet(`${SERVER_URL}/products/${productId}/alerts`);
const addUpdateSolutionVisibility = (productId, payload) => authPost(`${SERVER_URL}/products/${productId}/version-visibility`, payload);

const getVersionVisibility = productId => authGet(`${SERVER_URL}/products/${productId}/version-visibility`);

const updateChartVersionValidation = (productId, payload) => authPost(`${SERVER_URL}/products/${productId}/validation-status`, payload);

const  getEncryptedZipUrl = payload => authPost(`${SERVER_URL}/products/download-encrypted-zip`, payload);

const  createEncryptedZipRequest = payload => authPost(`${SERVER_URL}/products/request-encrypted-zip`, payload);

const getEncryptedZipList = (pagination = JSON.stringify({ page: 1, pageSize: 5 })) => authGet(`${SERVER_URL}/products/encrypted-downloads?pagination=${pagination}`);

export {
    getProductDetails,
    addNewProduct,
    updateProduct,
    getProductsList,
    getManagedProductsList,
    changeProductStatus,
    deleteProduct,
    getDeployableProductList,
    updateDeploymentImageUrl,
    getBillingsOrDeployedsList,
    getProductBillings,
    getDeployedProductConsumers,
    getRatingsList,
    getProductReviews,
    postProductReview,
    subscribeSaasProduct,
    getOperationSystems,
    getContentTypes,
    getSolutionAreas,
    unsubscribeDeployment,
    archiveOwnProduct,
    archiveMetaFile,
    republishOwnProduct,
    searchProducts,
    changeProductOvaStatus,
    changeBlueprintFileStatus,
    getDashboardList,
    getCategories,
    addDraftProduct,
    getDraftProductDetails,
    getDraftProductsList,
    deleteDraftProduct,
    addNewListProduct,
    updateListProduct,
    getPreSignedDownloadUrl,
    getSolutionAreaDetailsList,
    getCertificationTypeList,
    getVmwareProducts,
    getDockerLogin,
    createRequestInfo,
    getLeadsList,
    deleteLeadInfo,
    getPreSignedTempUrl,
    requestCaptcha,
    createUpdateCertification,
    getCertification,
    deleteCertification,
    updateAddonAssetUrl,
    syncVmwareProducts,
    changeProductVersionStatus,
    getMPCategoryHeirarchy,
    getMPCategories,
    addMpCategoriesAdmin,
    updateMpCategoriesAdmin,
    deleteMpCategoriesAdmin,
    setFeaturedProductStatus,
    setPopularProductStatus,
    getVersionSpecificProductDetails,
    getversionHeaderDetails,
    getAllCompatiblePlatforms,
    getSKUList,
    updateSKU,
    createAlertForSolution,
    getAllAlerts,
    updateAlertForSolution,
    getAllAlertsData,
    getAlertSeverityList,
    addUpdateSolutionVisibility,
    getVersionVisibility,
    unsubscribeRegistrySolution,
    updateChartVersionValidation,
    getSolutionLogos,
    getGarRoCredentials,
    adminSearchProducts,
    getDownloadsSummary,
    getProductsDownloadDetail,
    getEncryptedZipUrl,
    createEncryptedZipRequest,
    getEncryptedZipList
};


