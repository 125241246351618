import React from "react";
import { withRouter } from "react-router";
import { get, isEmpty } from "../../helpers";
import { getOwnProductStatus } from "./Helper";
import { ProductSuggestionCard } from "./ProductSuggestionCard";
import "./ProductSuggestionCard-dark.css";
import "./ProductSuggestionCard.css";
import getMedia from "../../helpers/mediaMapper";

export default withRouter(class OwnProductSuggestionCard extends ProductSuggestionCard {

    onCardClick() {
        const isVSX = get(this.props, "data.isvsx", false);

        if (isVSX) {
            return this.handleVSXProductClick();
        }

        let to = `/services/details/${this.getProp("data.slug", "")}?serviceMgmt=true&slug=true`;
        const { history: { replace } } = this.props;
        replace(to);
        this.props.onItemClick();
    }

    renderStatus() {
        if (isEmpty(this.getProp("data.status"))) return null;

        return (
            <span className={`status-labels ${getOwnProductStatus(this.getProp("data.status")).classname}`}>
                {getOwnProductStatus(this.getProp("data.status")).status}
            </span>
        );
    }

    renderPlatformsIconsAndStatuses() {
        const platformsList = this.getProp("data.deploymentplatformsList", []);

        if (platformsList.length < 1) return null;

        const icons = platformsList.map(this.renderDeploymentIcon);

        return (<div className="icons-statuses-container">{icons}{this.renderStatus()}</div>);
    }

    render() {
        const { data: { productlogo } } = this.props;
        const productLogoURL = get(productlogo, "url", null);

        return (
            <div className="suggestion-container" onClick={this.onCardClick}>
                <div className="suggestion-logo-container">
                    <img alt="Suggestion Logo"
                        className="suggestion-logo"
                        src={!isEmpty(productLogoURL) ? productLogoURL : getMedia("product-logo-placeholder.png")}
                    />
                </div>
                <div className="suggestion-content-container">
                    <span
                        className="suggestion-product-name"
                    >
                        {this.getProp("data.displayname")}
                    </span>
                    {this.renderProductDetails()}
                    {this.renderPlatformsIconsAndStatuses()}
                    {this.shouldRenderTape()}
                </div>
            </div>
        );
    }

});
