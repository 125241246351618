import React, { Component } from "react";
import APP_CONFIG from "../../config/app";
import { authHelper } from "../../helpers/authHelper";
import { relogin } from "../../services/api/sso";
import { tokenHelper } from "../../services/helpers/tokenHelper";
import Logger from "../../helpers/logger";
import "./styles.css";
import KEYS from "../../config/keys";

const ENV = ((process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "dev") ? "DEV" : "PROD");
const CURRENT_CONFIG = APP_CONFIG.SSO[ENV.toLocaleUpperCase()];
const PRIMARY = CURRENT_CONFIG.APP_BASE;
class SessionAlertPopup extends Component {

    constructor(props){
        super(props);

        // Second before alert popup opens
        this.defaultSessionAlertTime = 60;

        this.state = {
            showPopup: false,
            tokenRefreshInProgress: false,
            secondsToExpiry: 0,
            sessionExpired: false,
            userClosed: false,
            sessionAlertTime: this.defaultSessionAlertTime
        };

        this.pollAuthToken = this.pollAuthToken.bind(this);
        this.refreshAuthToken = this.refreshAuthToken.bind(this);
    }

    componentDidMount(){
        const { passon } = this.props;
        const token = tokenHelper.getAuthToken();
        const secondsToExpiry = tokenHelper.getTokenExpiryTime() - Math.round((new Date()).getTime() / 1000);

        if (!passon && token && secondsToExpiry >= 0) {
            this.pollAuthToken();
        }
    }

    pollAuthToken(){
        const {sessionAlertTime} = this.state;
        const secondsToExpiry = tokenHelper.getTokenExpiryTime() - Math.round((new Date()).getTime() / 1000);
        let nextCheck = secondsToExpiry > sessionAlertTime+20 ? 10000: 1000;
        if(secondsToExpiry < sessionAlertTime+1 && secondsToExpiry > 0){
            this.setState({
                showPopup: true,
                secondsToExpiry
            });
        }

        setTimeout(() => {
            this.pollAuthToken();
        }, nextCheck);

        if(secondsToExpiry <= 0){
            this.setState({
                showPopup: true,
                sessionExpired: true
            });
            //Once session expired, need to delete this hasVACAccess key
            //From localstorage so that, after session expired, vac related menu
            //could invisible.
            tokenHelper.deleteTokenByName(KEYS.HAS_VAC_ACCESS);
        }
    }

    refreshAuthToken(event){
        const that = this;
        const {sessionExpired} = this.state;
        event.preventDefault();

        if(sessionExpired){
            tokenHelper.deleteAuthToken();
            window.location = "/vsc";
            return;
        }

        this.setState({
            tokenRefreshInProgress: true
        });

        const refreshToken = tokenHelper.getRefreshToken();

        relogin({ refreshToken })
            .then(data => {

                const { access_token, refresh_token, id_token, orgId, user, userId, user_tracking_id } = data.data.response || {};

                tokenHelper.setAllTokens(access_token, refresh_token, id_token, user, user_tracking_id);
                tokenHelper.setTokenByName("current_org", orgId);
                tokenHelper.setTokenByName("userId", userId);

                that.setState({
                    showPopup: false,
                    secondsToExpiry: 0,
                    tokenRefreshInProgress: false
                });
            }, function () {
                authHelper.redirectUserToLogout(PRIMARY);
            })
            .catch(err => {
                Logger.log(err);
            });
    }

    get sessionActionText() {
        const {sessionExpired} = this.state;
        return sessionExpired ? "Continue" : "Continue Session";
    }

    render(){

        const {showPopup, secondsToExpiry, tokenRefreshInProgress, sessionExpired} = this.state;

        if(!showPopup){
            return null;
        }

        return(
            <div className="sessionAlertPopup">
                <div className="modal">
                    <div className="modal-dialog" role="dialog" aria-hidden="false">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Warning</h3>
                            </div>
                            <div className="modal-body">
                                <p hidden={sessionExpired}>Your session is about to expire in {secondsToExpiry} seconds.</p>
                                <p hidden={!sessionExpired}>Your session expired due to inactivity. Please click on Continue to redirect to the homepage and login again.</p>
                            </div>
                            <div className="modal-footer">
                                <button disabled={tokenRefreshInProgress} className="btn btn-primary" type="button" onClick={this.refreshAuthToken}>
                                    {tokenRefreshInProgress ? "Please Wait..." : this.sessionActionText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop" aria-hidden="false"/>
            </div>
        );
    }
}

export default SessionAlertPopup;
