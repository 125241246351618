import React, {Fragment} from "react";
import {get} from "../../helpers";
import FeedBack from "./FeedBack";
import getMedia from "../../helpers/mediaMapper";
const config = require(`../../config/config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);
const swaggerDocLink = get(config, "swaggerDocLink", "");

const blogLink = [
    {
        label: "Blog",
        route: "https://blogs.vmware.com/cloud/vmware-marketplace/" 
    }
];
const socialMedia = [
    {
        label: "Twitter",
        icon: getMedia("twitter.svg"),
        route: "https://twitter.com/vmwmarketplace" 
    },
    {
        label: "Youtube",
        icon: getMedia("youtube.svg"),
        route: "https://youtube.com/playlist?list=PLNOz1mVhDkG7NNz6UyUzCqTbLj91GUvoZ" 
    }
];

const utilityContent = [
    {
        text: "Terms of Use",
        link: true,
        // earlier: https://www.vmware.com/help/legal.html
        route: "https://www.broadcom.com/company/legal/terms-of-use"
    },
    {
        text: "Privacy Policy",
        link: true,
        //earlier: https://www.vmware.com/help/privacy.html
        route: "https://www.broadcom.com/company/legal/privacy", 
        className: "privacy-policy"
    },
    {
        text: "Your California Privacy Rights",
        link: true,
        //earlier: https://www.vmware.com/help/privacy/california-privacy-rights.html#
        route: "https://www.broadcom.com/company/legal/privacy/policy"
    }
];

const CopyrightContent = ()=> <div tabIndex="0"><Fragment>Copyright &copy; 2005-2024 Broadcom. All Rights Reserved. <br/>The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries.</Fragment></div>;

const footerLinks = [
    {
        title: "VMware Marketplace",
        footerText: "Discover and deploy validated third-party and open-source solutions for various clouds",
        blogLink
    },
    {
        title: "Resources",
        links: [
            {
                label: "General Program Guide",
                route: "https://www.vmware.com/content/dam/digitalmarketing/vmware/en/pdf/products/market-place/vmw-marketplace-program-guide.pdf",
                icon : getMedia("link_chain_icon.svg")
            },
            {
                label: "Getting Started",
                route: "https://docs.vmware.com/en/VMware-Marketplace/services/getting-started-with-vmware-marketplace/GUID-1600333F-A492-4A97-8A5C-91348461D2A5.html", 
                icon : getMedia("link_chain_icon.svg")
            },
            {
                label: "Consumer Guide",
                route: "https://docs.vmware.com/en/VMware-Marketplace/services/vmware-marketplace-for-consumers.pdf?hWord=N4IghgNiBcIG4FsDuYBOBTABAtBrdALgA4RgDG6IAvkA", 
                icon : getMedia("link_chain_icon.svg")
            },
            {
                label: "FAQ",
                route: "https://www.vmware.com/content/dam/digitalmarketing/vmware/en/pdf/products/market-place/vmw-marketplace-faq.pdf", 
                icon : getMedia("link_chain_icon.svg")
            },
            {
                label: "Training & Demos",
                route: "https://youtube.com/playlist?list=PLNOz1mVhDkG7NNz6UyUzCqTbLj91GUvoZ", 
                icon : getMedia("link_chain_icon.svg")
            },
            {
                label: "Newsletter Archive",
                route: "https://blogs.vmware.com/cloud/2022/01/13/vmware-marketplace-past-newsletters/", 
                icon : getMedia("link_chain_icon.svg")
            },
            {
                label: "Provide Feedback",
                type: "custom",
                customComponent : FeedBack
            }
        ]
    },
    {
        title: "For Partners",
        links: [
            // VCSPCLOUD-10900
            // {
            //     label: "Publish with Us", 
            //     route: "https://docs.vmware.com/en/VMware-Marketplace/services/vmware-marketplace-for-publishers/GUID-BE30473D-CFDC-43C0-B309-E5BCC1A2726D.html?hWord=N4IghgNiBcIGYHsBOBbABHMBjALskAvkA",
            //     icon : getMedia("link_chain_icon.svg")
            // },
            {
                label: "Technology Partner Hub",
                route: "https://techpartnerhub.vmware.com/", 
                icon : getMedia("link_chain_icon.svg")
            }
            // PAGE NOT FOUND ERROR
            // {
            //     label: "Partner Connect",
            //     route: "https://www.vmware.com/partners/work-with-vmware/partner-connect.html",
            //     icon : getMedia("link_chain_icon.svg")
            // }
        ]
    },
    {
        title: "For Developers",
        links: [
            {
                label: "API Reference",
                route: swaggerDocLink, 
                icon : getMedia("link_chain_icon.svg")
            },
            {
                label: "Documentation",
                route: "https://docs.vmware.com/en/VMware-Marketplace/index.html", 
                icon : getMedia("link_chain_icon.svg")
            }
        ]
    }
    // ALL OF THESE LINKS REDIRECT TO: https://www.vmware.com/solutions/cloud-partners
    // {
    //     title: "Why Our Cloud",
    //     links: [
    //         {
    //             label: "VMware Cloud Trust Center",
    //             route: "https://cloud.vmware.com/trust-center",
    //             icon : getMedia("link_chain_icon.svg")
    //         },
    //         {
    //             label: "Skyline",
    //             route: "https://cloud.vmware.com/skyline",
    //             icon : getMedia("link_chain_icon.svg")
    //         },
    //         {
    //             label: "Workloads",
    //             route: "https://cloud.vmware.com/workloads",
    //             icon : getMedia("link_chain_icon.svg")
    //         },
    //         {
    //             label: "Cloud Economics",
    //             route: "https://cloud.vmware.com/cloud-economics",
    //             icon : getMedia("link_chain_icon.svg")
    //         },
    //         {
    //             label: "Cloud Services Engagement Platform",
    //             route: "https://cloud.vmware.com/cloud-services-engagement-platform",
    //             icon : getMedia("link_chain_icon.svg")
    //         },
    //         {
    //             label: "Any Cloud",
    //             route: "https://cloud.vmware.com/any-cloud",
    //             icon : getMedia("link_chain_icon.svg")
    //         }
    //     ]
    // }
];

export {socialMedia, utilityContent, CopyrightContent, footerLinks};
