const title = (props, propName, componentName) => {
    const value = props[propName];
    const { labelledBy } = props;

    if (value != null && typeof value !== "string") {
        return new Error(
            `Invalid ${propName} for ${componentName}. Title must be an String.`
        );
    } else if (labelledBy == null && value == null) {
        return new Error(
            `${componentName} requires the title or labelledBy properties to be defined`
        );
    } else if (labelledBy != null && value != null) {
        return new Error(
            `${componentName} cannot define both title and labelledBy props. Please, select one of the two properties`
        );
    } else {
        return null;
    }
};

export default {
    title
};
