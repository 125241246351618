import aiMachineLearning from "../publicImages/ai-machine-learning.svg";
import	addUpdateDefault	from "../publicImages/addUpdateDefault.svg";
import	addUpdateVideo	from "../publicImages/addUpdateVideo.svg";
import	analytics	from "../publicImages/analytics.svg";
import	ariaIcon from "../publicImages/aria_icon.svg";
import	businessApplicationServices	from "../publicImages/business-application-services.svg";
import	certificateLogo	from "../publicImages/certificate-logo.svg";
import	compute from "../publicImages/compute.svg";
import	databases	from "../publicImages/databases.svg";
import	developerTools	from "../publicImages/developer-tools.svg";
import	devops	from "../publicImages/devops.svg";
import	financialServices	from "../publicImages/financial-services.svg";
import	identity from "../publicImages/identity.svg";
import	managementAndMonitoring	from "../publicImages/management-and-monitoring.svg";
import	networking	from "../publicImages/networking.svg";
import	onpremIcon	from "../publicImages/onprem_icon.svg";
import	security	from "../publicImages/security.svg";
import	storage	from "../publicImages/storage.svg";
import	telco	from "../publicImages/telco.svg";
import	tkgiIcon from "../publicImages/tkgi_icon.svg";
import	vcdIcon from "../publicImages/vcd_icon.svg";
import	vmcIcon	from "../publicImages/vmc_icon.svg";
import othersIcon from "../publicImages/others_icon.svg";
import success from "../publicImages/success.png";
import KeswickIcon from "../publicImages/keswick_icon.svg";
import vmwareByBroadcom from "../publicImages/VMware-by-Broadcom-Logo.svg";
import linkChainIcon from "../publicImages/link_chain_icon.svg";
import externalLink from "../publicImages/external_link.svg";
import youtube from "../publicImages/youtube.svg";
import twitter from "../publicImages/twitter.svg";
import vmcVlt from "../publicImages/vmc_vlt.svg";
import vcdVlt from "../publicImages/vcd_vlt.svg";
import onpremVlt from "../publicImages/onprem_vlt.svg";
import productLogoPlaceHolder from "../publicImages/product-logo-placeholder.png";
import activeBlue from "../publicImages/active_blue.png";
import activeGreen from "../publicImages/active_green.png";
import activeRed from "../publicImages/active_red.png";
import activeYellow from "../publicImages/active_yellow.png";
import defaultBlue from "../publicImages/default_blue.png";
import defaultGreen from "../publicImages/default_green.png";
import deafultRed from "../publicImages/default_red.png";
import defaultYellow from "../publicImages/default_yellow.png";
import vmcMini from  "../publicImages/VMC_MINI.svg";
import vcdMini from "../publicImages/VCD_MINI.svg";
import onpremMini from "../publicImages/ONPREM_MINI.svg";
import tkgiTextIcon from "../publicImages/tkgi_text_icon.svg";
import onpremTextIcon from "../publicImages/onprem_text_icon.svg";
import vcdTextIcon from "../publicImages/vcd_text_icon.svg";
import vmcTextIcon from "../publicImages/vmc_text_icon.svg";
import svcCatalog from "../publicImages/service_catalog_light.svg";
import emptyAlerts from "../publicImages/empty-alerts.svg";


const mediaMapper = {
    "ai-machine-learning.svg": aiMachineLearning,
    "addUpdateDefault.svg": addUpdateDefault,
    "addUpdateVideo.svg": addUpdateVideo,
    "analytics.svg": analytics,
    "aria_icon.svg": ariaIcon,
    "business-application-services.svg": businessApplicationServices,
    "certificate-logo.svg": certificateLogo,
    "compute.svg": compute,
    "databases.svg": databases,
    "developer-tools.svg": developerTools,
    "devops.svg": devops,
    "financial-services.svg": financialServices,
    "identity.svg": identity,
    "management-and-monitoring.svg": managementAndMonitoring,
    "networking.svg": networking,
    "onprem_icon.svg": onpremIcon,
    "security.svg": security,
    "storage.svg": storage,
    "telco.svg": telco,
    "tkgi_icon.svg": tkgiIcon,
    "vcd_icon.svg": vcdIcon,
    "vmc_icon.svg": vmcIcon,
    "others_icon.svg": othersIcon,
    "success.png": success,
    "keswick_icon.svg": KeswickIcon,
    "VMware-by-Broadcom-Logo.svg": vmwareByBroadcom,
    "link_chain_icon.svg": linkChainIcon,
    "external_link.svg": externalLink,
    "youtube.svg": youtube,
    "twitter.svg": twitter,
    "onprem_vlt.svg": onpremVlt,
    "vcd_vlt.svg": vcdVlt,
    "vmc_vlt.svg": vmcVlt,
    "product-logo-placeholder.png": productLogoPlaceHolder,
    "active_blue.png": activeBlue,
    "active_green.png": activeGreen,
    "active_red.png": activeRed,
    "active_yellow.png": activeYellow,
    "default_blue.png": defaultBlue,
    "default_green.png": defaultGreen,
    "default_red.png": deafultRed,
    "default_yellow.png": defaultYellow,
    "VMC_MINI.svg": vmcMini,
    "VCD_MINI.svg": vcdMini,
    "ONPREM_MINI.svg": onpremMini,
    "tkgi_text_icon.svg": tkgiTextIcon,
    "onprem_text_icon.svg": onpremTextIcon,
    "vcd_text_icon.svg": vcdTextIcon,
    "vmc_text_icon.svg": vmcTextIcon,
    "service_catalog_light.svg": svcCatalog,
    "empty-alerts.svg": emptyAlerts
};

const getMedia = mediaName => mediaMapper[mediaName] || null;

export default getMedia;
