
export const appConfig = require(`../config/config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);
/**
 * Gets the value at path of object.
 *
 * If the resolved value is undefined or null,
 * the defaultValue is returned in its place.
 *
 * @param  {object|array} source
 * @param  {string}       path
 * @param  {*}            defaultValue
 * @return {*}
 *
 * @example
 *   let example = {
 *     first: {
 *       array: [
 *         { property: 'Hello" }
 *       ]
 *     }
 *   };
 *   let secondExample = [
 *     {},
 *     {
 *       array: [ { 1: 'Hello' } ]
 *     }
 *   ];
 *
 *   get(example, 'first.array.0.property'); // Will return 'Hello';
 *   get(example, 'first.array'); // Will return [ { property: 'Hello' } ];
 *   get(secondExample, '1.array.0.1'); // Will return 'Hello';
 *   get(secondExample, '0'); // Will return {};
 *   get(secondExample, '0.some', 'Default Value'); // Will return 'Default Value';
 */
export function get(source, path, defaultValue) {
    if (typeof path === "number") {
        path = String(path);
    }

    if (path && source) {
        let parts = path.split(".");
        let length = parts.length;
        let result = source;

        for (let i = 0; i < length; i++) {
            let item = result[parts[i]];

            if (item === null || item === undefined) {
                return item || defaultValue;
            }

            result = item;
        }

        return result;
    }

    return defaultValue;
}

/**
 * Sets the value at path of variable.
 *
 * If a portion of path doesn't exist, it's created.
 *
 * Arrays are created for missing index properties
 * while objects are created for all other missing properties.
 *
 * @param  {object|array} source
 * @param  {string}       path
 * @param  {*}            value
 * @return {object|array}
 */
export function set(source, path, value) {
    if (typeof path === "number") {
        path = String(path);
    }

    if (source && path) {
        let parts = path.split(".");
        let length = parts.length - 1;
        let target = source;

        for (let i = 0; i < length; i++) {
            let part = parts[i];

            if (target[part] === undefined || target[part] === null) {
                target[part] = Number.isNaN(Number(parts[i + 1])) ? {} : [];
            }

            target = target[part];
        }

        target[parts[parts.length - 1]] = value;
    }

    return source;
}

/**
 * @param  {string} key
 * @param  {object} target
 * @return {array}
 */
export function getCookie(key, target) {
    try {
        if (isEmpty(target)) {
            target = get(document, "cookie");
        }
    } catch (err) {
        return;
    }

    if (isEmpty(target) && typeof target !== "string") {
        return;
    }

    const result = (target.match(new RegExp(key + "=.*?(;|$)", "gmi")) || [])
        .map(function (item) {
            return item.replace(/;$/gmi, "").split("=")[1];
        });

    return result && ((result.length === 1 && result[0]) || result);
}

/**
 * @param  {*} value
 * @return {boolean}
 *
 * @example
 *   isEmpty({  }) // true;
 *   isEmpty([]) // true;
 *   isEmpty('') // true;
 *   isEmpty(' ') // false [space here!];
 *   isEmpty(0) // false;
 *   isEmpty(undefined) // true;
 *   isEmpty(null) // true;
 *   isEmpty({ some: 'text' }) // false;
 *   isEmpty([ {} ]) // false;
 *   isEmpty('Hello World') // false;
 */
export function isEmpty(value) {
    return value === null ||
        value === undefined ||
        value === "undefined" ||
        value === "" ||
        (isArray(value) && !value.length) ||
        (isObject(value) && !Object.keys(value).length);
}

export function isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/**
 * @param  {array} array
 * @return {array}
 *
 * @example
 *   let array = [1, 1, 2, 3, 4, 5, 1, 5];
 *
 *   unique(array); // Will return new array [1, 2, 3, 4, 5];
 */
export function unique(array) {
    if (!isArray(array)) {
        return;
    }

    return array.reduce(function (acc, item) {
        if (
            !acc.some(function (ai) {
                return ai === item;
            })
        ) {
            return acc.concat(item);
        }

        return acc;
    }, []);
}

/**
 * @param  {string}  value
 * @param  {boolean} force
 * @return {string}
 *
 * @example
 *   let text = '    Hello     World!     How do you                   fill?';
 *   let email = '   example@         example.          com';
 *
 *   trim(text); // Will return 'Hello World! How do you fill?';
 *   trim(email, true); // Will return 'example@example.com';
 */
export const trim = (value, force) => {

    if (value && typeof value === "string") {
        return value.trim().replace(/ +/gmi, !!force ? "" : " ");
    }

    return value;
};

/**
 * @param  {*} source
 * @return {*}
 */
export function parse(source) {
    let result;

    try {
        result = JSON.parse(source);
    } catch (err) {
        result = source;
    }

    return result;
}

/**
 * @param  {*} source
 * @return {string}
 *
 * @example
 *   let a = { c: 2 };
 *   let b = { g: 3 };
 *   a.b = b;
 *   b.a = a;
 *
 *   JSON.stringify(a); // Will throw Error 'Uncaught TypeError: Converting circular structure to JSON';
 *   stringify(a); //  "{"c":2,"b":{"g":3}}";
 */
export function stringify(source, size) {
    let cache = [];

    return JSON.stringify(source, function (key, value) {
        if (typeof value === "object" && value !== null) {
            if (cache.indexOf(value) !== -1) {
                return;
            }

            cache.push(value);
        }

        return value;
    }, size || 0);
}

/**
 * @param  {*} object
 * @return {boolean}
 */
export function isObject(object) {
    return object && !isArray(object) && typeof object === "object";
}

/**
 * @param  {*} array
 * @return {boolean}
 */
export function isArray(array) {
    return array && typeof array === "object" && Array.isArray(array);
}

/**
 * @param  {object|array} target
 * @param  {array}        sources
 * @return {*}
 */
export function assignDeep(target, ...sources) {
    if (!sources.length) 
        return target;
    
    let source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (let key in source) {
            if (isObject(source[key])) {
                if (isEmpty(target[key])) {
                    target[key] = {};
                }

                assignDeep(target[key], source[key]);
            } else if (isArray(source[key])) {
                if (isEmpty(target[key])) {
                    target[key] = [];
                }

                assignDeep(target[key], source[key]);
            } else {
                target[key] = source[key];
            }
        }
    } else if (isArray(target) && isArray(source)) {
        let length = source.length;

        for (let i = 0; i < length; i++) {
            if (isObject(source[i])) {
                if (isEmpty(target[i])) {
                    target[i] = {};
                }

                assignDeep(target[i], source[i]);
            } else {
                target[i] = source[i];
            }
        }
    }

    return assignDeep(target, ...sources);
}

export function debounce() {
    let timeout;

    /**
     * @param {function} callback
     * @param {number}   time
     */
    return function (callback, time) {
        clearTimeout(timeout);

        timeout = setTimeout(callback, time || 300);
    };
}

/**
 * @param  {string} text
 * @param  {number} length
 * @return {string}
 */
export function ellipsis(text, length) {
    if (isEmpty(text)) {
        return;
    }

    if (typeof text !== "string") {
        return;
    }

    if (isEmpty(length)) {
        length = 128;
    }

    if (text.length > length - 3) {
        return text.slice(0, length - 3) + "...";
    }

    return text;
}

export function scrollToY(target, scrollTargetY) {
    let currentTime = 0;
    const scrollY = target.scrollTop;
    const speed = 1000;
    const ease = pos => Math.sin(pos * (Math.PI / 2));

    const time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

    (function tick() {
        currentTime += 1 / 60;

        const p = currentTime / time;
        const t = ease(p);

        if (p < 1) {
            window.requestAnimationFrame(tick.bind(this));

            target.scrollTop = scrollY + ((scrollTargetY - scrollY) * t);
        } else {
            // this.scroll = false;
            target.scrollTop = scrollTargetY;
        }
    }).call(this);
}


/**
 *
 * gets the unix timestamp
 * converts it to Date timestamp
 * returns date in UTC format YYYY-MM-DD
 *
 * @param {number} date
 * @returns {string}
 *
 *
 */
export function parseUnixDate(date) {
    if (isEmpty(date)) {
        return;
    }

    if (typeof date !== "number") {
        return;
    }

    const currentDate = new Date(date * 1000);
    const year = currentDate.getUTCFullYear();
    const month = currentDate.getUTCMonth() + 1;
    const day = currentDate.getUTCDate();

    return `${month}/${day}/${year}`;
}

/**
 * @param  {string} value
 * @return {boolean}
 */
export function isEmail(value) {
    // eslint-disable-next-line
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gmi.test(
        value
    );
}

/**
 * @param  {string}  value
 * @return {boolean}
 */
export function isUrl(value) {
    const regexp =  /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
    return regexp.test(value) ? true : false;
}

export function isUuid(value) {
    const regexp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    return regexp.test(value);
}

export function isValidHTSNumber(value) {
    const regexp = /^([0-9.])+$/;
    return regexp.test(value) ? true : false;
}

/**
 * @param  {string}  value
 * @return {boolean}
 */
export function isRegistryUrl(value) {
    const regexp =   /[a-z0-9]+(?:[._-][a-z0-9]+)*/;
    return regexp.test(value) ? true : false;
}

/**
 * @param  {string}  value
 * @return {boolean}
 */
export function isYoutubeUrl(value) {
    const regexp = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return regexp.test(value) ? true : false;
}

/**
 * @param  {string}  value
 * @return {boolean}
 */
export function isNumber(value) {
    // const regex = new RegExp("^\\d+$");
    const regex = /^([+]|\d)[^-\s().,][0-9]{1,14}$/g;
    return regex.test(value);
}

/**
 * @param  {string} str
 * @return {string}
 */
export function lowerFirst(str) {
    return str.charAt(0).toLocaleLowerCase() + str.slice(1);
}

/**
 * @param  {string} str
 * @return {string}
 */
export function upperFirst(str) {
    return str ? str.charAt(0).toLocaleUpperCase() + str.slice(1) :"";
}

/**
 * @param  {string} value
 * @param  {string} type
 * @return {boolean}
 */
export function validate(value, type) {
    if (isEmpty(type)) {
        type = "text";
    }

    if (isEmpty(value)) {
        return false;
    }

    if (type === "email") {
        return isEmail(value);
    }

    if (type === "url") {
        return isUrl(value);
    }

    return true;
}

/**
 * If condition is true it will return className
 *
 * @param  {boolean} condition
 * @param  {string} className
 * @return {string}
 */
export function getClassName(condition, className) {
    return condition
        ? className
        : "";
}


/**
 * Returns new object removing reference for source object.
 * Copies each nested object
 *
 * @param  {object} object
 * @return {object} cloned object
 */
export function deepCopy(object) {
    if(typeof object !== "object" || object === null) {
        return object;
    }

    if(object instanceof Date) {
        return new Date(object.getTime());
    }

    if(object instanceof Array) {
        return object.reduce((arr, item, i) => {
            arr[i] = deepCopy(item);
            return arr;
        }, []);
    }

    if(object instanceof Object) {
        return Object.keys(object).reduce((newObj, key) => {
            newObj[key] = deepCopy(object[key]);
            return newObj;
        }, {});
    }
}

/**
 *
 * Returns true if rule (part of a string) is present
 * in common string
 *
 * @param {string} str
 * @param {string} rule
 * @returns {boolean}
 */
export function isStrIncludes(str, rule) {
    if (isEmpty(str)) return false;

    return str.indexOf(rule) > -1;
}

// TODO: Refactor required
export function getChartBackground(invert = false) {
    const currenttheme = window.localStorage.getItem("theme");
    let theme = currenttheme;
    if (currenttheme !== "DARK") {
        if (currenttheme !== "LIGHT") {
            theme = "LIGHT";
        }
    }
    if(invert) {
        return (theme === "LIGHT" ? "#16252b" : "#FFFFFF");
    }

    return (theme === "LIGHT" ? "#FFFFFF" : "#16252b");
}

export function getExternalUrl(url){
    const pattern = /^((http|https):\/\/)/;

    if(!pattern.test(url)) {
        return `\\\\${url}`;
    }
    return url;
}

export function getUrlParams(search) {
    if (search) {
        const hashes = search.slice(search.indexOf("?") + 1).split("&");
        return hashes.reduce((acc, hash) => {
            const [
                key,
                val
            ] = hash.split("=");
            return {
                ...acc,
                [key]: decodeURIComponent(val)
            };
        }, {});
    }
    return {};
}

export function convertToUrlParams(object = {}) {
    return Object.keys(object).map(key => key + "=" + object[key]).join("&");
}

export function camelToKebabCase(text = "") {
    return text.replace(/(.)([A-Z][a-z]+)/, "$1-$2").replace(/([a-z0-9])([A-Z])/, "$1-$2").toLowerCase();
}
export function resetQueryParams() {
    const { search, href} = window.location;
    if (search) {
        const url = href.split("?")[0];
        window.history.pushState("object", document.title, url);
    }
}

export function setQueryFilterParam(data, keys) {

    if(isEmpty(data)) return;

    keys.forEach(key => {
        const valuesStr = (data[key] || []).map(item => encodeURIComponent(item)).join(",");

        if ("URLSearchParams" in window && !!data[key]) {
            let searchParams = new URLSearchParams(window.location.search);
            let keyDisplayName = key;
            if(key === "deploymentPlatforms.type")
                keyDisplayName = "deploymentPlatforms";
        
            if(!valuesStr || valuesStr === "") {
                searchParams.delete(keyDisplayName);
            }else {

                searchParams.set(keyDisplayName, valuesStr);
            }
            let pathname = window.location.pathname;
            pathname = pathname[pathname.length-1] === "/" ? pathname : pathname+"/";
            const newRelativePathQuery = pathname + "?" + searchParams.toString();
            window.history.pushState(null, "", newRelativePathQuery.replaceAll("%2520", "%20").replaceAll("+", "%20"));
        } 
    });
}

export function getCurrentTime() {
    return new Date().getTime();
}

export function convertMillisecToSec(ms) {
    return  Math.round(ms / 1000);
}

export function convertArrayToObject(array){
    const initialValue = {};
    return array.reduce((obj, item) => ({
        ...obj,
        [item]: true
    }), initialValue);
}

export function humanReadableFileSize(bytes, si = true) {
    const thresh = si ? 1000 : 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + " B";
    }
    const units = si
        ? [
            "kB",
            "MB",
            "GB",
            "TB",
            "PB",
            "EB",
            "ZB",
            "YB"
        ]
        : [
            "KiB",
            "MiB",
            "GiB",
            "TiB",
            "PiB",
            "EiB",
            "ZiB",
            "YiB"
        ];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+" "+units[u];
}

export function renameObjProp(obj, propName, newPropName) {
    if (obj[propName]) {
        obj[newPropName] = obj[propName];
        Reflect.deleteProperty(obj, propName);
    }
    return obj;
}

export function getDateTime(timestamp) {
    const date = new Date(timestamp*1000);
    const monthsList = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    const month = monthsList[date.getMonth()];
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0"+minutes : minutes;
    return `${month} ${date.getDate()} at ${hours}:${minutes} ${ampm}`;
}

export function getCompleteDateTime(timestamp) {
    if (isEmpty(timestamp)) {
        return;
    }
    if (typeof timestamp !== "number") {
        return;
    }
    const day = parseUnixDate(timestamp);

    const currentDate = new Date(timestamp * 1000);
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0"+minutes : minutes;

    return `${day} ${hours}:${minutes} ${ampm}`;

}

export function isPhone(phone) {
    const regex = /^[+]?[(]?[0-9]{2,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/;
    return regex.test(phone);
}

export function getLinkQueryParams(type, slug){ 
    return `?${type}=${slug}`;
}

export function stripHtmlTags(html = "") {
    let tmp = document.createElement("DIV");
    if(typeof html === "string") {
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    return "";
}

export function getFeatureFlagValue(ffClient, flag) {
    if (!isEmpty(ffClient) && ffClient.error) {
        return false; // If there is any error, return false to that feature flag
    }else if ( !isEmpty(ffClient) ) {
        return ffClient.client && ffClient.client.evaluate(flag);
    }
}

export function isPCAEnabled() {
    const PCA_ORGS = appConfig["PCA_ORGS"] || [];
    const currentOrg = window.localStorage.getItem("current_org");
    return PCA_ORGS.includes(currentOrg);
}

export function setNoScroll(noScroll) {
    const mainContainer = document.getElementById("main-content-area");
    if(noScroll) {
        mainContainer.classList.add("no-scroll");
    } else {
        mainContainer.classList.remove("no-scroll");
    }
}

export function camelToSentenceCase(value) {

    if(value && typeof value === "string") {
        const result = value.replace( /([A-Z])/g, " $1" );
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return trim(finalResult);
    }

    return null;
}

export function getTextFromHTML(htmlStr) {
    if(typeof htmlStr === "string") {
        return htmlStr.replaceAll(/(<([^>]+)>)/gi, "");
    }

    return "";
}

export function getPriceRegex() {
    return /(?=^[1-9])^[0-9]{0,6}(\.[0-9]{0,2})?$|^0{0,6}(\.((?=[1-9])[1-9]([0-9])?|[0-9][1-9]))$/;
}


export function getQuantityRegex() {
    return /^[1-9]\d*$/;
}

export function getSkuRegex() {
    return /^[\w\W]{0,40}$/;
}

export function getSkuDescRegex() {
    return /^[\w\W]{0,100}$/;
}

/**
 * The regex method to validate numerical values of maximum 7 digits length.
 * @returns Boolean
 */
export function getTTLValueRegex() {
    return /^[0-9]{0,7}?$/;
}

export function isPositiveFloat(value) {
    const regex = /^([0-9]*[.])?[0-9]+$/;
    return regex.test(value);
}

export function checkIfAllBooleanTrue(...args) {
    const valid = args.every(val => !!val); 
    return valid;
}

export function bindOnce(target, eventType, callback) {
    target.removeEventListener(eventType, callback);
    target.addEventListener(eventType, callback);
}

export function moveScrollToTop(targetId = "main-content-area") {
    const scrollWindow = targetId ? document.getElementById(targetId) : window;
    if(targetId) {
        scrollWindow.scrollTop = 0;
    } else {
        scrollWindow.scrollTo(0, 0);
    }
}

export function toTitleCase(value) {
    if(value && typeof value === "string") {
        const words = value.toLowerCase().split(" ");
        const finalString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
        return finalString;
    }
}

export function getCurrentDate() {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    const result = `${dd}-${mm}-${yyyy}`;
    return result;
}

export function isGcsPresignedUrl(url) {
    try {
        const parsedUrl = new URL(url);

        const gcsParams = [
            "X-Goog-Algorithm",
            "X-Goog-Credential",
            "X-Goog-Date",
            "X-Goog-Expires",
            "X-Goog-Signature"
        ];

        // Check if the URL contains all the necessary GCS presigned URL parameters
        const hasGcsParams = gcsParams.every(param => parsedUrl.searchParams.has(param));

        return hasGcsParams;
    } catch (error) {
        return false;
    }
}