import React, {Fragment, useContext, useState} from "react";
import {useLocation, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import NavDataManager from "./navDataManager";
import DropDownMain from "./DropDownMain";
import DropdownList from "./DropdownList";
import AppSearch from "../AppSearch";
import { isGlobalPublishAllowed } from "../../services/helpers";
import { authHelper } from "../../helpers/authHelper";
import {get, isEmpty} from "../../helpers";
import {getFeatureFlagValue} from "../../helpers";
import APP_CONFIG from "../../config/app";
import { needExactRouteCheck } from "./navDataList";
import cn from "classnames";
import { vacContext } from "../../appContext";
import { isTACForAdvUser, isTACStandaloneCustomer } from "../../containers/VACView/utils/userData";
import "./SubNav.scss";
import { CdsIcon } from "@cds/react/icon";
import { ClarityIcons, barsIcon, timesIcon } from "@cds/core/icon";
import Button from "../Button/Button";

ClarityIcons.addIcons(barsIcon, timesIcon);

const config = require(`../../config/config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);
const CSP_FEATURE_FLAGS = get(config, "CSP_FEATURE_FLAGS", "");

const CURRENT_ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "prod";
const CURRENT_CONFIG = APP_CONFIG.SSO[CURRENT_ENV.toLocaleUpperCase()];
const signupUrl = get(CURRENT_CONFIG, "CONTEXT_URL", "");

SubNav.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired
};

SubNav.defaultProps = {
    isLoggedIn: false
};

/*
 Main wrapper for SubNav Component
 It use NavDataManager for getting navigation items and passes is down to:
 DropDownMain for top level navItems and DropdownList for sub level navItems
 */

function SubNav(props) {
    const {isLoggedIn, history, ffClient, navInlineSearchVisible} = props;
    const {store} = useContext(vacContext);

    const [
        isNavExpanded,
        setIsNavExpanded
    ] = useState(false);

    const isStandaloneCustomer = isTACStandaloneCustomer(store);
    const isAdvancedCustomer = isTACForAdvUser(store);
    const naveDataObj = new NavDataManager(isLoggedIn, ffClient, isStandaloneCustomer, isAdvancedCustomer);
    const {ISV_ONBOARDING, VAC_CLIENT, HOME_PAGE_V2} = CSP_FEATURE_FLAGS;
    // Theme switcher
    // TODO: Need to implement and connect this to global theme switcher using context api.
    // const [
    //     theme,
    //     setTheme
    // ] = useState("DARK");

    const showIsvButton = getFeatureFlagValue(ffClient, ISV_ONBOARDING);
    // const toggleTheme = ()=> theme === "DARK" ? setTheme("LIGHT") : setTheme("DARK");

    const { pathname, search="" } = useLocation();

    const publishWithUsHandler = ()=> {
        // VCSPCLOUD-10900
        window.open("https://tap.broadcom.com/", "_blank");

        // if(isLoggedIn) {
        //     history.push({pathname: "/publisher-onboarding"});
        // } else {
        //     authHelper.redirectToSSO("/publisher-onboarding");
        // }
    };
    const isActiveNavItem = navSlug => {
        const subMenu=naveDataObj.getNavMenu(navSlug);
        return subMenu.find(obj => {
            //exact match check is needed where basepath is same but, these nav item appears under different nav menu
            if(needExactRouteCheck.includes(pathname)) {
                return obj.route && (pathname+search) === obj.route;
            }
            return obj.route && (pathname+search).startsWith(obj.route);
        });
    };

    const expandNavMenu = () => {
        setIsNavExpanded(isExpanded => !isExpanded);
    };

    const isHomePageEnabled=getFeatureFlagValue(ffClient, HOME_PAGE_V2);
    const navClasses = cn({"expanded" : isNavExpanded}, {"new-homepage": isHomePageEnabled}, "subnav");

    return (
        <Fragment>
            <nav className={navClasses}>
                <div className="nav">
                    {
                        naveDataObj.getMainNavItems().map(navData => {
                            const isActive = isActiveNavItem(navData.slug);
                            const displayClassName = cn("nav-item", {"active-nav-item": isActive});
                            return (
                                <li key={navData.slug} className={displayClassName}>
                                    <DropDownMain displayName={navData.displayname}>
                                        <DropdownList list={naveDataObj.getNavMenu(navData.slug)} activePage={!isEmpty(isActive) ? isActive : ""}/>
                                    </DropDownMain>
                                </li>
                            );
                        })
                    }
                </div>
                <ul className="nav right-section">
                    {/* <li className="nav-item">
                        <button className="btn btn-link btn-icon" onClick={toggleTheme}>
                            <clr-icon size="18" shape={theme === "DARK" ? "moon" : "sun"}></clr-icon> {theme === "DARK" ? "Dark" : "Light"}
                        </button>
                    </li> */}
                    {
                        isLoggedIn && !naveDataObj.isAdmin && showIsvButton && !getFeatureFlagValue(ffClient, VAC_CLIENT)
                        && history.location.pathname !== "/publisher-onboarding" && !isGlobalPublishAllowed() &&
                    <li className="nav-item">
                        <button className="right-section-nav-item btn btn-link btn-icon" onClick={publishWithUsHandler}>
                            Publish with Us
                        </button>
                    </li>
                    }
                    {
                        !isLoggedIn &&
                    <li className="nav-item">
                        <a className="right-section-nav-item btn btn-link btn-icon" href={signupUrl}>
                            Create Marketplace Account
                        </a>
                    </li>
                    }
                </ul>
                {isLoggedIn && isHomePageEnabled && (
                    <Button
                        flat
                        icon
                        className="navbar-hamburger"
                        onClick={expandNavMenu}
                        type="button"
                        aria-haspopup={true}
                        aria-expanded={isNavExpanded}
                        aria-label="Open the menu"
                    >
                        <CdsIcon size="28" shape={isNavExpanded ? "times" : "bars"} aria-hidden={true} altText="Open Menu" />
                    </Button>)}
            </nav>
            {
                navInlineSearchVisible  && !isHomePageEnabled &&
                <div className={cn("landingpage-search-wrapper", {"expanded": isNavExpanded})}>
                    <AppSearch
                        onClear={()=>false}
                        onSearch={()=>false}
                        type="public"
                        placeholder="Search Solutions"
                        id="homepage-search"
                        compact
                    />
                </div>
            }
        </Fragment>
    );
}

export default withRouter(SubNav);
