import React, {Fragment} from "react";
import PropTypes from "prop-types";
import "./footer.css";
import {footerLinks, socialMedia, utilityContent, CopyrightContent} from "./footerConfig";
import Newsletter from "./Newsletter";
import getMedia from "../../helpers/mediaMapper";

const Footer = props => {

    const { isAuthValid = false } = props;

    // Function to trigger the Header Sign In Button
    //TODO: Change this logic
    const handleLogin = () => {
        const loginButton = document.getElementsByClassName("btn-sign-in");
        loginButton[0] && loginButton[0].click();
    };

    return (
        <Fragment>
            {/* Footer Site Map*/}
            <div className="footer-links">
                {footerLinks.map((item, index)=>(
                    <div className="footer-section" key={index}>
                        <div>
                            <h6 tabIndex="0" className="footer-link-title">{item.title}</h6>
                            {item.footerText &&
                                <div className="footer-text-container">
                                    <span tabIndex="0" className="footer-text-content">{item.footerText}</span>
                                </div>
                            }
                            {item.links && item.links.map((link, linkIndex) => link.type === "custom" ?
                                <link.customComponent isAuthValid={isAuthValid}>
                                    <button tabIndex="0" role="link" id="feedback-link" className="feedback-link footer-link-text">{link.label}</button>
                                </link.customComponent> :
                                <div className="footer-link-text">
                                    <a aria-label={`${link.label} Opens in New Window`} rel="noopener noreferrer" target="_blank" className="footer-link" href={link.route} key={linkIndex}>
                                        {link.label}
                                        {link.icon && <img className="footer-link-icon" src={link.icon} alt="" />}
                                    </a>
                                </div> 
                            )}
                        </div>
                        {item.blogLink &&
                            <div className="footer-login-links-container">
                                {item.blogLink.map((link, index) => (
                                    <div className="footer-link-text"key={index}>
                                        <a aria-label={`${link.label} Opens in New Window`} rel="noopener noreferrer" target="_blank" href={link.route} key={index} className="footer-login-link">
                                            <span>{link.label}</span>
                                        </a>
                                    </div>
                                ))}
                                { !isAuthValid &&
                                    <button key={index} className="footer-link-button" onClick={handleLogin}>
                                        Sign In
                                    </button>
                                }
                                <Newsletter isAuthValid={isAuthValid} />
                            </div>
                        }
                    </div>
                ))}
            </div>
            {/* Footer Social Media Section */}
            {!!socialMedia &&
                <div className="footer-social-container">
                    {socialMedia.map((social, index) => (
                        <a aria-label={`${social.label} Opens in New Window`} key={index} href={social.route} rel="noopener noreferrer" target="_blank" className="footer-social-link">
                            <img src={social.icon} alt={social.label} className="footer-social-icon"/>
                        </a>
                    ))}
                </div>
            }
            {
                !!utilityContent &&
                <div className="utility-content-container">
                    {/* Footer Copyright Content */}
                    <div className="copyright-content"><CopyrightContent /></div>
                    {/* Footer Privacy Policy Links Section */}
                    {utilityContent.map((item, index) => (
                        <a role="link"  aria-label={`${item.text} Opens in New Window`} className={`footer-privacy-container footer-link-text ${!!item.className ? item.className : ""}`} href={item.route} key={index} target="_blank" rel="noopener noreferrer">
                            <span>{item.text}</span>
                            <img src={getMedia("external_link.svg")} alt="External" />
                        </a>
                    ))}
                </div>
            }
            {/* Footer Logo */}
            <div tabIndex="0" className="footer-logo">
                <img src={getMedia("VMware-by-Broadcom-Logo.svg")} alt="VMware Logo" />
            </div>

        </Fragment>);
};

Footer.propTypes = {
    isAuthValid: PropTypes.bool.isRequired // User authectication status to display Login Option
};

export default Footer;