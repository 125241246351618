import axios from "axios";
import appConfig from "../../config/app";
import common from "../../config/common-ui";
import serverConfig from "../../config/server";
import Logger from "../../helpers/logger";
import { tokenHelper } from "./tokenHelper";

// Get current env setting, by default if nothing is passed we will assume that its dev
const CURRENT_ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "prod";
const APP_CONFIG = appConfig.SSO[CURRENT_ENV.toLocaleUpperCase()];

// const { CSP_HOST_STG, CLIENT_ID, CLIENT_SECRET } = APP_CONFIG;
const { CSP_HOST_STG} = APP_CONFIG;

const authCalls = axios.create();
const publicCalls = axios.create();

authCalls.interceptors.request.use(config => {

    // let originalRequest = config;
    config.url = encodeURI(config.url);
    const accessToken = tokenHelper.getAuthToken();

    // const isTokenExpired = tokenHelper.isTokenExpired();
    // const renewToken = tokenHelper.generateNewAuthToken; //Function reference

    // if (isTokenExpired) {

    //     const tokenRenewUrl = `${CSP_HOST_STG}/csp/gateway/am/api/auth/authorize`;

    //     tokenHelper.generateNewAuthToken(CLIENT_ID, CLIENT_SECRET, tokenRenewUrl).then(data => {
    //         const { access_token, refresh_token } = data.data;

    //         //Set cookies and local storage
    //         tokenHelper.setAllTokens(access_token, refresh_token);
    //         //Add token to request
    //         originalRequest["csp-auth-token"] = access_token;
    //         originalRequest["Authorization"] = access_token;
    //         return Promise.resolve(originalRequest);

    //     }, function (err) {
    //         tokenHelper.clearAllTokens();
    //         //Remove token and call logout
    //         getLogoutUrl(idToken).then(data => {
    //             tokenHelper.clearAllTokens();
    //             window.location = data.data.url;
    //         });

    //         // TODO: Set a error state common across whole application
    //         return Promise.reject(err);
    //     });
    // } else {
    // config.headers.Authorization = accessToken;
    config.headers["csp-auth-token"] = accessToken;
    // }

    return config;
}, err => {
    Logger.log("From interceptor");
    Promise.reject(err);
});

authCalls.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (checkCancelToken(error)) {
        return Promise.reject(error);
    } 

    const isOnline = navigator.onLine;
    if (typeof error.response === "undefined") {
        error = {
            response: {
                data: {
                    response: { message: isOnline ? "No response from the server." : "Internet not available." }
                }
            },
            code: error.code ? error.code : null
        };
    }
    if (localStorage && localStorage.setItem && error && error.response && error.response.status && error.response.status === 401) {
        localStorage.setItem("is_token_valid", false);
    }
    Logger.log("From interceptor", error);
    return Promise.reject(error);
});


/*
* Build request config from required params
*/
const buildConfig = (url, method, payload, contentType) => {

    if (method.toLocaleLowerCase() === "get") {
        return url;
    }

    return {
        method,
        url,
        data: payload,
        config: { headers: { "Content-Type": contentType } }
    };
};

/*
* Function is for all request where tokens are required
*/
const authCall = (url, method, payload, contentType) => {

    if (method.toLocaleLowerCase() === "get") {
        Logger.log("is coming here");
        Logger.log(url);
        Logger.log(payload);
        const params = payload;
        return authCalls.get(url, { params });
    }

    const config = buildConfig(url, method, payload, contentType);
    return authCalls(config);
};

/*
* Function is for all request where authentication is not required
*/
const publicCall = (url, method, payload, contentType) => {

    if (method.toLocaleLowerCase() === "get") {
        const params = payload;
        return publicCalls.get(url, { params });
    }

    const config = buildConfig(url, method, payload, contentType);

    return publicCalls(config);
};

export const getUserType = () => window.localStorage.getItem("current_permission");

/*
* Wrapper function to handle all kind of request.
* requestConfig will take : authenticated, url, method, payload
* authenticated need to be true for all authenticated calls
*/
export const request = (requestConfig, contentType = "application/json") => {
    const { authenticated, url, method, payload } = requestConfig;

    if (authenticated) {
        return authCall(url, method, payload, contentType);
    } else {
        return publicCall(url, method, payload, contentType);
    }
};

/**
 * Get logout url from CSP
 */
export const getLogoutUrl = token => publicCalls.post(CSP_HOST_STG + "/csp/gateway/am/api/auth/logout", { "idToken": token });


//////------- Remove everything below this after changes are done ------ ///////
export function authPost(url, payload, isvCenterLogin, customConfig = {}) {
    return authCalls({
        method: "post",
        url,
        data: payload,
        timeout: isvCenterLogin ? common.timeout : 0,
        config: { headers: { "Content-Type": "application/json" } },
        ...customConfig
    });
}

export function authPut(url, payload, customConfig = {}) {
    return authCalls({
        method: "put",
        url,
        data: payload,
        config: { headers: { "Content-Type": "application/json" } },
        ...customConfig
    });
}

export function authPutUpload(url, file, config = {}) {
    return authCalls({
        method: "put",
        url,
        file,
        config: { headers: { "Content-Type": "application/json" } },
        ...config
    });
}

export function authPatch(url, payload, customConfig = {}) {
    return authCalls({
        method: "patch",
        url,
        data: payload,
        config: { headers: { "Content-Type": "application/json" } },
        ...customConfig
    });
}

export function authDelete(url, payload) {
    return authCalls({
        method: "delete",
        url,
        data: payload,
        config: { headers: { "Content-Type": "application/json" } }
    });
}

export function authGet(url, params, config) {
    Logger.log("is coming here");
    Logger.log(url);
    Logger.log(params);
    return authCalls.get(url, { params, ...config });
}

export function publicPost(url, payload) {
    return publicCalls({
        method: "post",
        url,
        data: payload,
        config: { headers: { "Content-Type": "application/json" } }
    });
}

export function publicGet(url, params) {
    return publicCalls.get(url, { params });
}

export function getServerUrl() {
    const CURRENT_ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "prod";
    return serverConfig[CURRENT_ENV].url;
}

export const createCancelToken = () => axios.CancelToken.source();

export const checkCancelToken = error => axios.isCancel(error);

export default authCalls;
